.header {
    display: flex;
    margin: 0 auto;
    padding: 20px 0 15px;
    max-width: 1150px;
    justify-content: space-between;
    align-items: center;
}

.header-logo {
    width: 172px;
    height: 30px;
}

.header-email {
    color: #428bca;
    text-decoration: none;
    font-size: 17px;
}

.header-phone {
    color: #428bca;
    text-decoration: none;
    font-size: 17px;
}

.header-call {
    color: #428bca;
    text-decoration: none;
    font-size: 17px;
}

.header-minicart {
    color: #428bca;
    text-decoration: none;
}

.header-text {
    font-size: 13px;
    font-weight: 400;
}

.header-sum {
    color: #1d70b7;
    font-size: 19px;
    font-weight: 700;
}