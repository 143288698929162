.footer {
    background: blue;
    padding: 20px 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 10px;
    margin: 20px auto 0;
}

.footer__text {
    color: white;
}