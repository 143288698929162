.constructor {
    padding: 78px 0 50px;
    max-width: 1150px;
    margin: 0 auto;
}

.constructor__background {
    background: #f3f3f3;
}

.constructor__title {
    text-align: left;
    margin: 0 0 28px;
}

.constructor__container {
    display: flex;
    width: 100%;
}