.nav {
    background: #eee;
    box-shadow: 0 0 1px 1px rgba(0, 1, 1, .21);
}

.nav-container {
    display: flex;
    column-gap: 8px;
    margin: 0 auto;
    max-width: 1150px;
}

.nav-link {
    color: #3498db;
    text-decoration: none;
}

.nav-text {
    margin: 0;
    color: #999;
}