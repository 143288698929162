.typeSelection {
    margin: 45px auto 0;
    max-width: 1150px;
}

.typeSelection__title {
    margin: 0 0 40px;
    text-align: left;
}

.typeSelection__articles {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
}

.typeSelection__article {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px;
}
.typeSelection__article:hover .typeSelection__article__article-image {
    border: 1px solid #4d93cd;
}
.typeSelection__article:hover .typeSelection__article__article-name {
    color:  #4d93cd;
}

.typeSelection__article__article-image {
    width: 138px;
    height: 138px;
    border-radius: 5px;
    border: 1px solid #fff;
    pointer-events: none;
}
.typeSelection__article__article-image:hover {
    cursor: pointer;
}
.typeSelection__article__article-image_active {
    border: 1px solid #4d93cd;
}

.typeSelection__article__article-name {
    margin: 0;
    pointer-events: none;
}
.typeSelection__article__article-name:hover {
    cursor: pointer;
}
.typeSelection__article__article-name_active{
    color:  #4d93cd;
}


.typeSelection__article__article-price {
    margin: 0;
    color: #878787;
    font-size: 12px;
    font-weight: 600;
    pointer-events: none;
}