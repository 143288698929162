.basket {
    margin: 0 auto;
    max-width: 1150px;
    border: 1px solid #ddd;
    /* padding: 30px 60px; */
    /* display: flex;
    flex-direction: column; */
}

.basket__purchase {
    display: flex;
    align-items: center;
    column-gap: 10px;
}

.basket__purchase-delete {
    background: none;
    border: none;
}
.basket__purchase-delete:hover {
    cursor: pointer;
}

.basket__purchase-name {
    color: #333;
    font-size: 13px;
    font-weight: 700;
    margin: 0;
}

.basket__purchase-info {
    color: #333;
    font-size: 13px;
    margin: 0;
}

.basket__purchase-x {
    margin: 0;
}

.basket__purchase-x-span {
    color: #2a6496;
    font-size: 14px;
    font-weight: 700;
}

.basket__purchase-count-btns {

}

.basket__purchase-count-btn {
    cursor: pointer;
}

.basket__purchase-price {

}

.basket__total {

}

.basket__order {
    cursor: pointer;
}