.designer {
    margin: 45px auto 0;
    max-width: 1150px;
}

.desinger__title {
    margin: 0 0 40px;
    text-align: left;
}

.designer__nav {
    display: flex;
    column-gap: 1px;
}

.designer__nav-btn {
    border: 1px solid #eee;
    background: #eee;
    border-radius: 4px 4px 0 0;
    cursor: pointer;
    min-width: 170px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 42px;
    margin: 0 0 -1px;
    padding: 0 0 1px;
}
.designer__nav-btn_active {
    border: 1px solid #ddd;
    background: #fff;
    border-bottom-color: #fff;
}

.designer__nav-btn-img {
    width: 25px;
    height: 25px;
    margin: 0 10px 0 0;
    pointer-events: none;
}

.designer__nav-btn-name {
    margin: 0;
    pointer-events: none;
}

.designer__tab {
    border: 1px solid #ddd;
    padding: 30px 60px;
    display: flex;
    flex-direction: column;
}

.designer__tab-term {
    text-align: left;
}

.designer__tab-control {
    display: flex;
    column-gap: 250px;
    margin: 0 0 30px;
    font-size: 15px;
}

.designer__tab-control-select {
    max-width: 360px;
    font-size: 15px;
    text-overflow: ellipsis;
}

.designer__tab-table-container {
    border-top: 34px solid #f5f5f5;
    margin: 0 -60px;
    padding: 0 60px;
}

.designer__tab-table {
    width: 100%;
    font-size: 15px;
    margin: -34px 0 15px 0;
}

.designer__tab-table-th {
    padding: 7px;
}

.designer__tab-table-td {
    padding: 7px;
    border-radius: 5px;
    border: 1px solid #fff;
}
.designer__tab-table-td:first-of-type {
    font-weight: 600;
    pointer-events: none;
}
.designer__tab-table-td:not(:first-of-type):hover {
    background: #f5f5f5;
    border: 1px solid #e6e6e6;
    cursor: pointer;   
}
.designer__tab-table-td_active{
    background: #d9edf6;
    border: 1px solid #bee8f1;
}

.designer__tab-btn {
    background: #3498db;
    font-size: 15px;
    color: #fff;
    border-radius: 4px;
    border: none;
    padding: 8px 10px;
    width: max-content;
}
.designer__tab-btn:hover {
    cursor: pointer;
    background: #1478ab;
}

.designer__design {
    display: flex;
    border: 1px solid #ddd;
    padding: 30px 60px;
}

.designer__design-table {
    width: 100%;
    font-size: 15px;
    border-right: 1px solid #ddd;
    padding: 15px 30px 85px 0;
    position: relative;
}

.designer__design-table-th {
    padding: 7px;
    color: #1d70b7;
}
.designer__design-table-th:first-of-type {
    text-align: left;
}

.designer__design-table-td {
    padding: 7px;
    border-radius: 5px;
    border: 1px solid #fff;
}
.designer__design-table-td:first-of-type {
    font-weight: 600;
    text-align: left;
}
.designer__design-table-td:not(:first-of-type):hover {
    background: #f5f5f5;
    border: 1px solid #e6e6e6;
    cursor: pointer;   
}
.designer__design-table-td_active{
    background: #d9edf6;
    border: 1px solid #bee8f1;
}

.designer__design-info {
    text-align: left;
    padding: 40px 0 0 28px;
}

.designer__design-info-title {
    font-size: 16px;
    font-weight: 700;
}

.designer__design-info-text {
    font-style: italic;
}

.designer__design-icons {
    display: flex;
    column-gap: 5px;
}

.designer__design-icon {
    width: 45px;
    height: 55px;
}

.designer__design-btn {
    background: #3498db;
    font-size: 15px;
    color: #fff;
    border-radius: 4px;
    border: none;
    padding: 8px 10px;
    width: max-content;
    position: absolute;
    bottom: 0;
    left: 0;
}