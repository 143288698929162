.printing-house {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    max-width: 1150px;
    margin: 0 auto 30px;
    gap: 15px;
}

.printing-house__article {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.printing-house__article:hover .printing-house__article-image {
    border: 1px solid #4d93cd;
}

.printing-house__article-image {
    width: 138px;
    height: 138px;
    border-radius: 5px;
    border: 1px solid #fff;
}
.printing-house__article-image:hover{
    cursor: pointer;
}

.printing-house__article-name {
    margin: 0;
}
.printing-house__article-name:hover {
    color:  #4d93cd;
    cursor: pointer;
}

.printing-house__article-price {
    margin: 0;
    color: #878787;
    font-size: 12px;
    font-weight: 600;
}