.constructor__form {
    display: flex;
    width: 100%;
    position: relative;
}

.constructor__form-inputs {
    display: flex;
    flex-direction: column;
    width: 60%;
}

.constructor__form-label {
    display: flex;
    align-items: center;
    position: relative;
    height: 40px;
    color: #333;
    font-size: 14px;
    font-weight: 600;
}

.constructor__form-input {
    border: 1px solid #ccc;
    border-radius: 5px;
    background: #fff;
    color: #333;
    font-size: 13px;
    padding: 6px 10px;
    position: absolute;
    top: 6px;
    left: 150px;
    width: 50px;
}
.constructor__form-input_x{
    left: 250px;
}

.constructor__form-input-unit {
    margin: 0;
    position: absolute;
    top: 12px;
    left: 230px;
}
.constructor__form-input-unit_x {
    left: 330px;
}

.constructor__form-input-multiplier {
    margin: 0;
    position: absolute;
    top: 14px;
    left: 230px;
}

.constructor__form-select {
    position: absolute;
    top: 6px;
    left: 150px;
    width: 200px;
    padding: 5px 30px 5px 10px;
}
.constructor__form-select_small {
    width: 70px;
    padding: 5px 0px 5px 10px;
}

.constructor__form-submit-container {

}
.constructor__form-unit-price {
    color: #b4b4b4;
    margin: 8px 0;
}

.constructor__form-total-price {
    font-size: 28px;
    font-weight: 700;
    line-height: 1;
    margin: 0 0 25px;
}

.constructor__form-submit {
    background: #3498db;
    font-size: 18px;
    font-weight: 600;
    padding: 10px 20px;
    border-radius: 6px;
    color: #fff;
    border: none;
}
.constructor__form-submit:hover{
    cursor: pointer;
    background: #1478ab;
}

.constructor__form-link {
    color: #3498db;
    text-decoration:underline;
}

.constructor__form-input_checkbox {
    position: absolute;
    top: 10px;
    left: 145px;
}

.constructor__form-covers {
    display: flex;
    column-gap: 100px;
    margin: 30px 0 70px 28px;
}

.constructor__form-cover {
    
}

.constructor__form-cover-title {
    margin: 0 0 5px;
    font-size: 15px;
}

.constructor__form-cover-btn {
    text-align: left;
    border: 1px solid #D2D2D2;
    border-radius: 10px;
    cursor: pointer;
    padding: 12px;
    transition: all .2s ease;
    font-size: 13px;
    background: none;
    position: relative;
    max-width: 150px;
}
.constructor__form-cover-btn:hover{
    background-color: #fff;
}

.constructor__form-cover-info {
    margin: 0;
    pointer-events: none;
}

.constructor__form-cover-btn-pen {
    display: block;
    width: 20px;
    height: 17px;
    margin: 5px 0 5px auto;
    pointer-events: none;
}

.constructor__form-cover-btn-img {
    display: block;
    width: 40px;
    height: 35px;
    position: absolute;
    top: -22px;
    left: -28px;
    pointer-events: none;
}

.constructor__form-popup {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    width: 360px;
    height: 270px;
    padding: 25px 35px;
}
.constructor__form-popup_active {
    display: block;
}

.constructor__form-popup-container {
    display: flex;
    margin: 30px 0 0;
}

.constructor__form-popup-img {
    opacity: 0.3;
}

.constructor__form-popup-name {
    color: #ccc;
    margin: 5px 10px 0;
    font-size: 15px;
}

.constructor__form-popup-pick {
    margin-left: auto;
    color: #fff;
    border-radius: 6px;
    border: 0;
    padding: 5px 25px;
    background: #3498db;
}
.constructor__form-popup-pick:hover {
    cursor: pointer;
}

.constructor__form-popup-close {
    position: absolute;
    border: none;
    background: none;
    top: 5px;
    right: 5px;
}
.constructor__form-popup-close:hover {
    cursor: pointer;
}