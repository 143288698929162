.info {
    border-left: 1px solid #d1d3d4;
    padding: 20px 0 20px 40px;
    color: #333;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    width: 40%;
}

.info__text {
    margin: 14px 0;
}

.info__image {
    width: 280px;
    height: 37px;
}

.info__image2 {
    width: 87px;
    height: 105px;
}

.info__term {
    font-weight: 700;
}

.info__first-model {
    margin: 0 auto;
    border: 1px solid #c3c3c3;
    background: #fff;
}
.info__first-model_alt {
    background-image: url(../../images/skoba.png);
    background-position: -5px;
    background-repeat: repeat-y;
}

.info__second-model {
    margin: 0 auto;
    border: 1px solid #c3c3c3;
    background: #fff;
    background-image: url(../../images/skoba2.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}
.info__second-model_alt1 {
    background-image: url(../../images/skoba.png);
    background-repeat: repeat-y;
    background-size: auto;
}
.info__second-model_alt2 {
    background-image: url(../../images/cley.png);
}
.info__first-model_alt3 {
    background-image: url(../../images/top-blocknot.png);
    background-repeat: repeat-x;
}


.info__model-text {
    text-align: center;
    color: #c3c3c3;
    margin: 5px;
}
.info__model-text_cal {
    text-align: left;
}

.info__image-container {
    display: flex;
    width: 300px;
    justify-content: space-between;
}

.info__model-text-container {
    margin: 22px 37px 0 0;
}

.info__image-cal {
    height: 170px;
    width: 230px;
}